import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Aeonik';
        src: url('/fonts/Aeonik-Medium.woff') format("woff");
        font-weight: 500;
        font-style: normal;
        font-display: swap
    }
    @font-face {
        font-family: Surt;
        src: url("/fonts/Surt-Regular.woff") format("woff");
        font-weight: 400;
        font-style: normal;
        font-display: swap
    }
      `}
  />
);

export default Fonts;
