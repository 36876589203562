import { useUser } from "@auth0/nextjs-auth0";
import * as React from "react";
import { useEffect, createContext, useReducer } from "react";
import { FixMeLater } from "types";

const initialState = {
  isAdmin: false,
  isLoading: false,
};
const ShowIfOpsAdmin = ({
  children,
  isLoadingComponent = <></>,
  elseComponent = <></>,
}: FixMeLater) => {
  const { isAdmin, isLoading } = useIsAdmin();

  if (isLoading) return isLoadingComponent;
  if (isAdmin) return children;
  if (!isAdmin) return elseComponent;
};

export const GlobalContext = createContext(initialState);

type Props = {
  children: React.ReactNode;
};

export const IsAdminProvider: React.FC<Props> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useReducer(() => true, false);
  const { user, isLoading } = useUser();
  const value = React.useMemo(
    () => ({
      isAdmin,
      setIsAdmin,
      isLoading,
    }),
    [isAdmin, isLoading]
  );
  useEffect(() => {
    if (isLoading) return;
    if (user && Array.isArray(user.roles)) {
      const isAdmin = user?.roles?.find((item) => item.name === "ops-admin");
      if (!!isAdmin) {
        setIsAdmin();
      }
    }
  }, [user, isLoading]);
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export function useIsAdmin() {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useIsAdmin must be used within a UserProvider");
  }
  return context;
}

export default ShowIfOpsAdmin;
