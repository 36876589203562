import { Box, Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import Image from "next/image";
const Login = () => {
  return (
    <SimpleGrid columns={[1, 1, 2]} w="90vw" h="100%" margin="auto">
      <Box w="100%" h="100vh" display={["none", "none", "block"]}>
        <Box width="90%" height="100%">
          <Image
            src="/flevo-women.png"
            alt="flevo women"
            height={992}
            width={802}
          />
        </Box>
      </Box>
      <Box w={["100%", "100%", "70%", "60%"]}>
        <Flex
          flexDirection="column"
          width="full"
          height="full"
          justifyContent="center"
          alignItems={["center", "center", "center", "end"]}
        >
          <Box maxWidth="120px" mb="8">
            <Image
              src="/flevo-logo-gradient-dark.png"
              width="441"
              height="421"
              alt="Flevo logo"
            />
          </Box>
          <Text fontSize="3xl" fontWeight="regular" color="black">
            Empoderamos personas
          </Text>
          <Text fontSize="3xl" fontWeight="regular" color="black">
            Confiamos en talentos
          </Text>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a href="/api/auth/login">
            <Button
              bg="flevo.jade"
              borderRadius="full"
              color="white"
              px="4"
              _hover={{ backgroundColor: "teal.900" }}
              mt="8"
            >
              ENTRAR
            </Button>
          </a>
        </Flex>
      </Box>
    </SimpleGrid>
  );
};

export default Login;
