import * as React from "react";
import { UserProvider } from "@auth0/nextjs-auth0";
import { ChakraProvider, extendTheme, Progress } from "@chakra-ui/react";

import { AppType } from "next/dist/shared/lib/utils";
import { trpc } from "utils/trpc";

import "../styles/globals.css";
import Fonts from "components/Fonts/Fonts";
import { IsAdminProvider } from "components/shared/ShowIfOpsAdmin";
import { useRouter } from "next/router";
import Head from "next/head";
import { Layout } from "components/shared/layout/Layout";
import Script from "next/script";
import { IsSchoolAdminProvider } from "components/shared/ShowIfSchoolAdmin";

import { Toaster } from 'sonner';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const theme = extendTheme({
  colors: {
    flevo: {
      jade: "#42B794",
      indigo: "#142644",
      sand: "#E9E9DC",
      lime: "#DFFF65",
      lyptus: "#AACBA7",
      darksand: "#F2DBBF",
    },
  },
  fonts: {
    heading: "Aeonik",
    body: "Surt",
  },
});


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1, // 1 minutes
      cacheTime: 1000 * 60 * 2, // 2 minutes. NOTE: default is 5 minutes.
    },
  },
})

const App: AppType = ({ Component, pageProps }) => {
  const [start, setStart] = React.useState("idle");
  const router = useRouter();

  const aniStart = () => {
    setStart("start");
  };

  const aniEnd = () => {
    setStart("idle");
  };
  
  React.useEffect(() => {
    router.events.on("routeChangeStart", aniStart);
    router.events.on("routeChangeComplete", aniEnd);
    router.events.on("routeChangeError", aniEnd);

    return () => {
      router.events.off("routeChangeStart", aniStart);
      router.events.off("routeChangeComplete", aniEnd);
      router.events.off("routeChangeError", aniEnd);
    };
  }, [router]);
  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>
      <UserProvider>
        <IsAdminProvider>
          <IsSchoolAdminProvider>
              <ChakraProvider theme={theme} resetCSS={false}>
                <Fonts />
                <QueryClientProvider client={queryClient}>
                  <Progress
                    size="xs"
                    isIndeterminate={start === "start"}
                    colorScheme="teal"
                  />
                  <Layout>
                    <Component {...pageProps} />
                    <Toaster position="top-center" />
                  </Layout>
                  {process.env.NODE_ENV === "production" && (
                    <Script
                      id="my-clarity"
                      strategy="afterInteractive"
                      dangerouslySetInnerHTML={{
                        __html: `
                      (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "dduef73x13");`,
                      }}
                    />
                  )}
                </QueryClientProvider>
              </ChakraProvider>
          </IsSchoolAdminProvider>
        </IsAdminProvider>
      </UserProvider>
    </>
  );
};

export default trpc.withTRPC(App)