import { useUser } from "@auth0/nextjs-auth0";
import Login from "components/Login";

type Props = {
  children: React.ReactNode;
};

export const Layout: React.FC<Props> = ({ children }) => {
  const { isLoading, user } = useUser();
  if (isLoading) return <></>;
  if (!user && !isLoading) return <Login />;
  return !isLoading && user ? <div>{children}</div> : null;
};
