import { useUser } from "@auth0/nextjs-auth0";
import * as React from "react";
import { useEffect, createContext, useReducer } from "react";
import { FixMeLater } from "types";

const initialState = {
  isSchoolAdmin: false,
  isLoading: false,
};
const ShowIfSchoolAdmin = ({
  children,
  isLoadingComponent = <></>,
  elseComponent = <></>,
}: FixMeLater) => {
  const { isSchoolAdmin, isLoading } = useIsSchoolAdmin();

  if (isLoading) return isLoadingComponent;
  if (isSchoolAdmin) return children;
  if (!isSchoolAdmin) return elseComponent;
};

export const GlobalContext = createContext(initialState);

type Props = {
  children: React.ReactNode;
};

export const IsSchoolAdminProvider: React.FC<Props> = ({ children }) => {
  const [isSchoolAdmin, setIsSchoolAdmin] = useReducer(() => true, false);
  const { user, isLoading } = useUser();
  const value = React.useMemo(
    () => ({
      isSchoolAdmin,
      setIsSchoolAdmin,
      isLoading,
    }),
    [isSchoolAdmin, isLoading]
  );
  useEffect(() => {
    if (isLoading) return;
    if (user && Array.isArray(user.roles)) {
      const isSchoolAdmin = user?.roles?.find(
        (item) => item.name === "school-admin"
      );
      if (!!isSchoolAdmin) {
        setIsSchoolAdmin();
      }
    }
  }, [user, isLoading]);
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export function useIsSchoolAdmin() {
  const context = React.useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useIsSchoolAdmin must be used within a UserProvider");
  }
  return context;
}

export default ShowIfSchoolAdmin;
